<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
                v-slot="{ handleSubmit, invalid, touched }"
                ref="passwordForm"
            >
              <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(changePassword)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                        v-model="password.password"
                        name="password"
                        rules="required|confirmed:confirm_password"
                        label="Password"
                        type="password"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                        v-model="password.password_confirmation"
                        vid="confirm_password"
                        name="confirm_password"
                        rules="required"
                        label="Confirm Password"
                        type="password"
                    />
                  </div>
                </div>
                <b-form-group class="text-right">
                  <b-button
                      variant="primary"
                      class="btn-dark-blue"
                      :disabled="loadingPassword || (invalid && touched)"
                      type="submit"
                  >
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loadingPassword: false,
      user: {
        agency: {},
      },
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', this.password)
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
